@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;0,700;0,800;1,400;1,700&display=swap');
body {
	margin: 0;
	font-family: 'Nunito', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* react-draft-wysiwyg link modal positioning */
/* to enable: set class 'letter-support-box' on the component as 'toolbarClassName' prop */
.letter-support-box > .rdw-link-wrapper > .rdw-link-modal {
	left: -117px;
}

img {
	color: transparent;
}

.moduleInstanceMain.slateEditor p {
	margin-bottom: 16px;
}
