.input {
	margin-top: 20px;
	background-color: 'green';
}

.no-suggestions {
	color: #999;
	padding: 0.4rem;
	font-size: 0.9rem;
}

.suggestions {
	border: 1px solid #007bff;
	border-top-width: 0;
	list-style: none;
	margin-top: 0;
	max-height: 143px;
	overflow-y: auto;
	padding-left: 0;
}

.suggestions::-webkit-scrollbar {
	width: 0.2rem;
	height: 0rem;
	border-radius: 20px;
}

.suggestions::-webkit-scrollbar-thumb {
	background-color: #007bff;
}

.suggestions li {
	padding: 0.5rem;
	font-size: 0.9rem;
	font-weight: 500;
}

.suggestion-active,
.suggestions li:hover {
	background: #007bff;
	color: #fff;
	cursor: pointer;
	font-weight: 700;
}

.suggestions li:not(:last-of-type) {
	border-bottom: 1px solid #999;
}
