.userHideRightSeparator > .MuiDataGrid-columnSeparator {
	visibility: hidden;
}

.userCellFormat > .MuiDataGrid-cellContent {
	font-family: Nunito, sans-serif;
}

.userHideRightSeparator.pr-0 {
	padding-right: 0;
}
